{
  "account": {
    "addEscrow": {
      "confirmDelegatingToInactive": {
        "description": "Doğrulayıcının durumu: {{validatorStatus}}. Yaptığınız delegasyonla herhangi bir ödül elde edemeyebilirsiniz.",
        "title": "Devam etmek istediğinize emin misiniz?"
      },
      "delegate": "Delege"
    },
    "loading": "Hesap yükleniyor",
    "loadingError": "Hesap yüklenmedi. Bilgiler tam ya da güncel olmayabilir.",
    "reclaimEscrow": {
      "reclaim": "Al"
    },
    "sendTransaction": {
      "confirmSendingToValidator": {
        "description": "Bu adres doğrulayıcıya aittir. Bu adrese yapılan transferler, birikiminizi doğrulayıcıda stake ettirtmez.",
        "title": "Devam etmek istediğine emin misin?"
      },
      "enterAddress": "Bir adres girin",
      "recipient": "Alıcı",
      "send": "Gönder",
      "success": "İşleminiz başarıyla gönderildi. İşlemin hesabınıza geçmesi bir dakikaya yakın sürebilmektedir."
    },
    "subnavigation": {
      "activeDelegations": "Aktif delegasyon",
      "debondingDelegations": "Ayrılmış delegasyon",
      "transactions": "İşlemler"
    },
    "summary": {
      "balance": {
        "available": "Uygun",
        "debonding": "Ayrılmış",
        "delegations": "Stake Edilmiş",
        "total": "Toplam bakiye"
      },
      "noTransactionFound": "İşlem bulunamadı",
      "noWalletIsOpen": "ROSE jetonlarınızı göndermek, almak, stake etmek ya da takas etmek için <HomeLink> cüzdanınızı açın! </HomeLink>",
      "notYourAccount": "Bu sizin hesabınız değil.",
      "yourAccount": "Bu sizin hesabınız"
    },
    "transaction": {
      "addEscrow": {
        "received": "Escrowdaki delegasyondan <Amount/> alındı",
        "sent": "Doğrulayıcıya <Amount/> delege edildi"
      },
      "genericTransaction": {
        "destination": "Diğer adres",
        "header": "Yöntemi '{{method}}'"
      },
      "loadingError": "İşlem geçmişinizi yükleyemiyoruz. Liste boş ya da süresi geçmiş olabilir.",
      "reclaimEscrow": {
        "received": "<Amount/> delegator tarafından alındı",
        "sent": "Doğrulayıcıdan <Amount/> alındı"
      },
      "transfer": {
        "received": "Alındı <Amount/>",
        "sent": {
          "header": "Gönderildi <Amount/>"
        }
      },
      "unrecognizedTransaction": {
        "destination": "Diğer adres",
        "header": "Tanınmayan işlem yöntemi '{{method}}'"
      }
    },
    "validator": {
      "loadingError": "Doğrulayıcıları yükleyemiyoruz.",
      "showingStale": "Doğrulayıcı listesini gösteriyoruz: {{staleTimestamp}}."
    }
  },
  "common": {
    "amount": "Miktar",
    "block": "Engelle",
    "cancel": "İptal et",
    "confirm": "Onayla",
    "delegator": "Delegatör",
    "from": "Kimden",
    "to": "Kime",
    "unavailable": "Uygun değil",
    "validator": "Doğrulayıcı",
    "validators": "Doğrulayıcılar"
  },
  "createWallet": {
    "confirmSaved": "Anahtar kelimelerimi kaydettim",
    "doNotShare": "Anahtar kelimenizi asla paylaşmayın, anahtar kelimelerinizi bilen herkes cüzdanınıza ve tokenlarınıza erişebilir.",
    "instruction": "Anahtar kelimelerinizi <strong>doğru sırada</strong> güvenli bir yere kaydedin, cüzdanınızı açmak için buna ihtiyacınız olacak.",
    "mnemonicMismatch": "Girdiğiniz anımsatıcılar birbiriyle eşleşmiyor.",
    "newMnemonic": "Yeni bir mnemonic oluştur",
    "thisIsYourPhrase": "Size ait olan mnemonic"
  },
  "delegations": {
    "activeDelegations": "Aktif delegasyonlar",
    "debondingDelegations": "Ayrılan delegasyonlar",
    "debondingEpoch": "İşlemi/dönemi ayır",
    "delegatedAmount": "Devredilen miktar",
    "reclaimedAmount": "Alınan miktar"
  },
  "errors": {
    "LedgerOasisAppIsNotOpen": "Ledger üzerindeki Oasis Uygulaması kapalı.",
    "cannotSendToSelf": "Kendinize gönderemezsiniz",
    "duplicateTransaction": "Tekrarlayan işlem",
    "insufficientBalance": "Yetersiz bakiye",
    "invalidAddress": "Geçersiz adres",
    "invalidNonce": "Geçersiz Nonce (transaction number)",
    "invalidPrivateKey": "Geçersiz anahtar kelime",
    "ledgerAppVersionNotSupported": "Alındı : \"Ledger uygulama versiyonu desteklenmiyor\", bu yanlış bir işlem olabilir, işlemi tekrar edin, eğer hata devam ederse Ledger'ınızdaki Oasis uygulamasını güncelleyin.",
    "ledgerCannotOpenOasisApp": "Oasis uygulamasını Ledger üzerinde açamadı, kilidin açık olduğundan ve Oasis uygulamasının açık olduğundan emin olun.",
    "ledgerNoDeviceSelected": "Ledger cihazı seçilmedi. Bağlı olduğundan emin olun, <LedgerHelp> Ledger ile USB bağlantınız arasındaki genel sorunları inceleyin </LedgerHelp> ve <ChromeHelp> site izinlerinin USB cihazınızı engellemediğinden emin olun </ChromeHelp>.",
    "ledgerTransactionRejected": "İşlem, Ledger tarafından reddedildi",
    "noOpenWallet": "Hiçbir cüzdan açık değil.",
    "unknown": "Bilinmeyen hata : {{message}}",
    "unknownGrpc": "Bilinmeyen gRPC hatası: {{message}}",
    "unknownLedgerError": "Bilinmeyen ledger hatası : {{message}}",
    "usbTransportError": "USB hatası : {{message}}",
    "usbTransportNotSupported": "Kullandığınız tarafıyıcı WebUSB'yi desteklemiyor. (Örneğin Firefox). Lütfen Chrome kullanmayı deneyin."
  },
  "fatalError": {
    "copied": "Hata panoya kopyalandı",
    "copy": "Hatayı panoya kopyala",
    "heading": "Önemli bir hata oluştu",
    "instruction": "Oasis Cüzdanı'nın çalışmasını zorunlu olarak durduran bir hata meydana geldi. Lütfen sayfayı yenileyin ve sorunun devam edip etmediğini görmek için cüzdanınızı tekrar açın. Eğer sorun devam ediyorsa lütfen <Email/> adresine e-posta atın ve hatayı ek olarak gönderin."
  },
  "footer": {
    "github": "Oasis Cüzdan tamamen <GithubLink>açık kaynaktır</GithubLink> - Geri bildirim ve sorunlar için teşekkür ederiz!",
    "poweredBy": {
      "oasismonitor": "Oasis Monitor API & Oasis gRPC tarafından destekleniyor",
      "oasisscan": "Oasis Scan API & Oasis gRPC tarafından destekleniyor"
    },
    "terms": "<TermsLink>Şartlar ve Koşullar</TermsLink>",
    "version": "Versiyon: <CommitLink/> {{buildTime}} tarihinde inşa edildi."
  },
  "home": {
    "create": {
      "button": "Cüzdan oluştur",
      "description": "ROSE tokenlarınızı göndermek, almak, stake etmek ve swap yapmak için yepyeni bir cüzdan oluşturun",
      "header": "Yeni cüzdan oluştur"
    },
    "existing": {
      "button": "Cüzdan aç",
      "description": "Ledger üzerinde saklanan mevcut cüzdanınızı açın, gizli anahtarınızı ya da anımsatıcı kelimelerinizi içeriye aktarın.",
      "header": "Mevcut cüzdana giriş yap"
    }
  },
  "ledger": {
    "instructionSteps": {
      "closeLedgerLive": "Bilgisayarınızdan Ledger Live Uygulamasını kapatın.",
      "connectLedger": "Lütfen Ledger cihazınızı bilgisayarınıza bağlayın",
      "header": "Adımlar:",
      "openOasisApp": "Oasis Uygulamasını, Ledger cihazınızda açınız."
    },
    "steps": {
      "done": "Tamam",
      "loadingAccounts": "Hesap detayları yükleniyor",
      "loadingBalances": "Bakiye detayları yükleniyor",
      "openingUsb": "Ledger USB Üzerinden Açılıyor"
    }
  },
  "menu": {
    "closeWallet": "Cüzdanı kapat",
    "home": "Ana Sayfa",
    "stake": "Stake",
    "wallet": "Cüzdan"
  },
  "openWallet": {
    "bitpie": {
      "warning": "BitPie cüzdan kullanıcıları: Anımsatıcı kelimelerinizi direkt olarak BitPie cüzdanınızda içeriye aktaramazsınız. <DocsLink>Detaylar için dökümana göz atınız. </DocsLink>"
    },
    "header": "Cüzdanınızı nasıl açmak istersiniz?",
    "ledger": {
      "cancel": "İptal et",
      "header": "Ledger cihazınızdan açınız",
      "openWallets": "Aç",
      "selectWallets": "Açmak istediğiniz hesapları seçin"
    },
    "method": {
      "ledger": "Ledger",
      "mnemonic": "Mnemonic",
      "privateKey": "Özel anahtar"
    },
    "mnemonic": {
      "enterPhraseHere": "Anahtar kelimelerinizi buraya girin",
      "error": "Geçersiz anahtar kelime. Lütfen kelimeleri doğru sırayla girdiğinizden emin olun, hepsi küçük harf olmalı.",
      "header": "Anahtar kelimelerinizi girin",
      "import": "Cüzdanımı içe aktar",
      "instruction": "12, 18 veya 24 kelimelik anahtar kelimenizi aşağıya girin, her kelime boşlukla ayrılmıştır."
    },
    "privateKey": {
      "enterPrivateKeyHere": "Özel anahtarınızı buraya girin",
      "error": "Geçersiz özel anahtar",
      "header": "Özel anahtarınızı girin",
      "hidePrivateKey": "Özel anahtarı gizle",
      "instruction": "Base-64 ile şifrelenmiş özel anahtarınızı girin",
      "showPrivateKey": "Özel anahtarı göster"
    }
  },
  "theme": {
    "darkMode": "Gece modu",
    "lightMode": "Gündüz modu"
  },
  "toolbar": {
    "networks": {
      "local": "Yerel",
      "mainnet": "Mainnet",
      "testnet": "Testnet"
    },
    "wallets": {
      "close": "Kapat",
      "switchOtherWallet": "Başka hesaba geç",
      "type": {
        "ledger": "Ledger",
        "mnemonic": "Anahtar kelime",
        "privateKey": "Özel anahtar"
      }
    }
  },
  "transaction": {
    "abort": "İptal et",
    "confirm": "Onayla",
    "preview": {
      "amount": "Miktar",
      "fee": "İşlem ücreti",
      "from": "Kimden",
      "gas": "Gas",
      "genesisHash": "Genesis Hash",
      "shares": "Paylaşanlar",
      "to": "Kime",
      "type": "Tip",
      "validator": "Doğrulayıcı",
      "warning": "Bu işlemi onayladıktan sonra iptal edemezsiniz. Dikkatlice inceleyin ve göndermek istediğinizden emin olduğunuzda onaylayın."
    },
    "step": {
      "building": "İşlem oluşturuluyor",
      "preview": "İşlemi önizle",
      "signing": "İşlem imzalanıyor",
      "submitting": "İşlem gönderiliyor"
    },
    "types": {
      "addEscrow": "Tokenları bir doğrulayıcıya devretme ve ödüller oluşturma",
      "reclaimEscrow": "Bir doğrulayıcıya devredilen tokenları geri alma",
      "transfer": "Tokenları bir hesabınızdan diğerine aktarma"
    }
  },
  "validator": {
    "boundsNotSet": "Sınır ayarlanmadı (0% - 100%)",
    "commission": "Komisyon",
    "commissionBounds": {
      "current": "<strong>{{lowerBound}}% - {{upperBound}}%</strong> şu anki dönemde {{epoch}}",
      "future": "{{lowerBound}}% - {{upperBound}}%  dönemden başlıyor {{epoch}}",
      "label": "Komisyon sınırları"
    },
    "escrow": "Escrow",
    "fee": "İşlem ücreti",
    "name": "İsim",
    "rank": "Rütbe",
    "status": "Durum",
    "statusActive": "Aktif",
    "statusInactive": "İnaktif",
    "statusUnknown": "Bilinmeyen",
    "totalEscrow": "Toplam Escrow",
    "unknownValidator": "Bilinmeyen doğrulayıcı"
  }
}
