/**
 *
 * AccountDetails
 *
 */
import { Box } from 'grommet/es6/components/Box'
import React, { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SendTransaction } from '../SendTransaction'
import { TransactionHistory } from '../TransactionHistory'
import { selectIsAddressInWallet } from 'app/state/selectIsAddressInWallet'
import { Button } from 'grommet/es6/components/Button'
import { useTranslation } from 'react-i18next'
import { transactionActions } from 'app/state/transaction'
import { selectTransaction } from 'app/state/transaction/selectors'
import { selectValidators } from 'app/state/staking/selectors'
import { parseRoseStringToBaseUnitString } from 'app/lib/helpers'
import { TransactionStatus } from 'app/components/TransactionStatus'
import { useModal } from 'app/components/Modal'
import { ResponsiveLayer } from 'app/components/ResponsiveLayer'

enum ActionOrigin {
	definodes = 'definodes',
	rosedrop = 'rosedrop',
	tpb = 'tpb',
	j5c = 'j5c',
}

interface User {
  walletId: string;
	origin: ActionOrigin;
	tokens: string;
	airdropTokens: string;
	pendingTokens: string;
}

interface Props {}

export const AccountDetails = memo((props: Props) => {
  const isAddressInWallet = useSelector(selectIsAddressInWallet)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { error, success } = useSelector(selectTransaction)
  const { launchModal } = useModal()

  const [users, setUsers] = useState<User[]>([])
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isSendAll, setIsSendAll] = useState<boolean>(false);
  const [sendAllCompleted, setSendAllCompleted] = useState<boolean>(false);

  const [counter, setCounter] = useState<number>(-1);
  const [txErrors, setTxErrors] = useState<string[]>([]);
  const [txSuccess, setTxSuccess] = useState<string[]>([]);

  useEffect(() => {
    const load = async () => {
      const response = await fetch('https://api.definodes.es/users', {
        headers: {
          password: '4LXWzx0ZRP6nOGtHARSoU0eAx'
        }
      });
      if (response.ok) {
        const data: User[] = await response.json();
        const filteredUsers = data.filter(user => Number(user.pendingTokens) !== 0);
        setUsers(filteredUsers);
        setIsloading(false);
      }
      setIsloading(false);
    }
    load();
  }, [])

  const onClickSend = (user: User) => {
    setSelectedUser(user);
  }

  const walletsCmp = () => {
    let ret = [];

    if (isLoading) {
      return (
        <div>
          Loading...
        </div>
      )
    }

    if (users.length) {
      ret.push(
        <div key={'header'} style={{fontSize: '10px', width: '100%', display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(12, minmax(0, 1fr))', borderBottom: '1px solid gray', textAlign: 'center'}}>
          <div >
            <p>#</p>
          </div>
          <div style={{gridColumn: 'span 5 / span 5'}}>
            <p>Wallet</p>
          </div>
          <div>
            <p>Origin</p>
          </div>
          <div>
            <p>Tokens</p>
          </div>
          <div>
            <p>Enviado</p>
          </div>
          <div>
            <p>Pendiente</p>
          </div>
        </div>
      )
    }

    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      ret.push(
        <div key={user.walletId + i} style={{fontSize: '14px', width: '100%', display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(12, minmax(0, 1fr))', borderBottom: '1px solid gray', textAlign: 'center'}}>
          <div>
            <p>{i}</p>
          </div>
          <div style={{gridColumn: 'span 5 / span 5', fontSize: '14px'}}>
            <p><strong>{user.walletId}</strong></p>
          </div>
          <div>
            <p>{user.origin}</p>
          </div>
          <div>
            <p>{`${Number(user.tokens).toString()}`}</p>
          </div>
          <div>
            <p>{`${Number(user.airdropTokens).toString()}`}</p>
          </div>
          <div>
            <p>{`${Number(user.pendingTokens).toString()}`}</p>
          </div>
          <div style={{ paddingTop: '14px' }}>
            <Button onClick={() => onClickSend(user)} type="submit" size='small'  label={t('account.sendTransaction.send', 'Send')} primary />
          </div>
        </div>
      )
    }

    return ret;
  }

  useEffect(() => {
    const newCounter = counter + 1;
    if (success) {
      setTxSuccess([...txSuccess, ''+selectedUser?.walletId])
    }
    else if (error) {
      setTxErrors([...txErrors, `${selectedUser?.walletId}: ${error.message}`])
    }
    if (counter != -1) {
      if (newCounter < users.length) {
        setTimeout(() => {
          setCounter(newCounter)
        }, 10000)
      }
      else {
        setSendAllCompleted(true);
        setCounter(-1);
        setIsSendAll(false);
      }
    }
  }, [success, error])

  const onClickSendAll = async () => {
    setIsSendAll(true);
    setSendAllCompleted(false);
    setTxErrors([]);
    setTxSuccess([]);
    setCounter(0);
  }

  const sendTransaction = (user: User) => {
    setSelectedUser(user);
    dispatch(
      transactionActions.sendTransaction({
        type: 'transfer',
        amount: parseRoseStringToBaseUnitString(user.pendingTokens),
        to: user.walletId,
      })
    )
    setTimeout(() => {
      dispatch(transactionActions.confirmTransaction())
    }, 1500)
  }

  useEffect(() => {
    if (counter != -1) {
      setIsSendAll(true);
      sendTransaction(users[counter]);
    }
  }, [counter])


  return (
    <Box direction="row-responsive" gap="small">
      {/* {isAddressInWallet && (
        <Box flex basis="1/4" width={{ min: '300px' }}>
          <SendTransaction isAddressInWallet={isAddressInWallet} />
        </Box>
      )} */}
      <Box background="background-front" round="5px" basis='80%'>
        <div style={{display: 'flex', padding: '10px', gap: '1rem', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
          <Button onClick={onClickSendAll} disabled={isSendAll} size='small' label={`Enviar a todos`} primary style={{flexGrow: 1, height: '40px'}}/>
          <div style={{fontSize: '12px'}}>
            <p>{`Tokens necesarios: ${users.reduce((accumulator, user) => Number(accumulator) + Number(user.pendingTokens), 0)}`}</p>
            <p>{`Total transacciones: ${users.length}`}</p>
          </div>
        </div>
        {/* <TransactionHistory /> */}
        <div style={{padding: '1rem'}}>
          {walletsCmp()}
        </div>
      </Box>
      {isAddressInWallet && !isSendAll && (
        <SendTransaction
          key={selectedUser?.walletId}
          isAddressInWallet={isAddressInWallet}
          recipient={selectedUser?.walletId}
          amount={selectedUser?.pendingTokens}
        />
      )}
      {isSendAll &&
        <Box border={{ color: 'background-front-border', size: '1px' }} round="5px" background="background-front" style={{padding: '5px'}}>
          Enviando <strong>{selectedUser?.pendingTokens}</strong> a <strong>{selectedUser?.walletId}</strong>
          <TransactionStatus error={error} success={success} />
        </Box>
      }
      {
       sendAllCompleted && <SendAllModal errors={txErrors} success={txSuccess} onClickClose={() => setSendAllCompleted(false)}/>
      }
    </Box>
  )
})


interface ModalProps {
  success: string[];
  errors: string[];
  onClickClose: () => void;
}
const SendAllModal = memo((props:ModalProps) => {

  return (
    <ResponsiveLayer modal position="center" background="background-front" style={{width: '600px'}}>
      <div style={{width: '100%', padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <div style={{width: '100%'}}>
          <p style={{fontSize: '12px', borderBottom: '1px solid'}}><strong>Success ({props.success.length})</strong></p>
          {props.success.map((success) => {
            return <p>{success}</p>
          })}
        </div>
        <div style={{width: '100%'}}>
          <p style={{fontSize: '12px', borderBottom: '1px solid'}}><strong>Errors ({props.errors.length})</strong></p>
          {props.errors.map((error) => {
            return <p>{error}</p>
          })}
        </div>
      </div>
      <Button onClick={props.onClickClose} size='small' label={'Cerrar'} primary style={{margin: '10px'}}/>
    </ResponsiveLayer>
  )
})
